import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
  })

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formState }),
    })
      .then(() =>
        alert(
          "Thanks for sending a message! Ryan will be sure to get back to you within 72 hours. Hang tight!"
        )
      )
      .catch(error => alert(error))

    e.preventDefault()
  }
  return (
    <Layout>
      <SEO title="User Experience Designer" />
      <div className="h-5/6 overflow-hidden relative lg:pb-20">
        <div className="pt-5 md:pt-0 text-3xl lg:text-4xl font-bold text-center lg:my-2">
          Business changes fast.
        </div>
        <div className="text-3xl lg:text-4xl font-bold text-center lg:my-2">
          Your <span className="text-yellow-500">designs</span> should too.
        </div>
        <img src="https://res.cloudinary.com/dl4c8irfl/image/upload/v1611184186/ryan-portfolio/designer-hero-image_2x_xaubzk.png" className="hidden md:block w-full px-2 lg:px-0 sm:w-5/6 h-auto lg:h-65vh lg:w-auto mx-auto mt-10" />
        <img src="https://res.cloudinary.com/dl4c8irfl/image/upload/v1611189232/ryan-portfolio/mobile-designer-hero_2x_tqowiu.png" className="md:hidden w-full px-5 lg:px-0 sm:w-5/6 h-auto lg:h-65vh lg:w-auto mx-auto mt-6" />
        

        <div className="-z-10 bg-primary-blue h-1/2 absolute -bottom-16 w-screen transform origin-top-right -skew-y-3"></div>
      </div>

      <div className="bg-primary-blue lg:flex items-center overflow-x-hidden">
        <div className="lg:w-2/5 flex justify-center">
          <div className="pt-20 pb-10 lg:py-0 max-w-lg lg:pl-20 px-4">
            <div className="text-3xl sm:text-2xl lg:text-3xl font-bold text-white lg:leading-10 mb-5">
              Developer friendly
              <br /> design that lets you
              <br />
              <span className="text-yellow-500">scale fast</span>
            </div>
            <div className=" text-white text-base border-yellow-500 border-l-4">
              <ol>
                <li className="flex">
                  <span className="">1.)</span>
                  <span className="ml-4">
                    Practical designs that seamlessly integrate into developers'
                    technology stacks.
                  </span>
                </li>
                <li className="flex">
                  <span className="">2.)</span>
                  <span className="ml-4">
                    Realistic interactive JS and React based prototypes
                    (Framer).
                  </span>
                </li>
                <li className="flex">
                  <span className="">3.)</span>
                  <span className="ml-4">
                    Re-useable components & design systems. <br />
                    Speed up your time to production.
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="lg:w-3/5 relative lg:flex items-end" style={{height:"85vh"}}>
          <img src="https://res.cloudinary.com/dl4c8irfl/image/upload/v1611184186/ryan-portfolio/coding-image_2x_xlq93a.png" className="absolute right-0 lg:left-0 mb-0 flex-shrink-0 flex-grow-0 h-full object-cover object-left" />
        </div>
      </div>
      <div className="bg-dark-blue text-center py-20">
        <div className="text-3xl lg:text-4xl font-bold text-center text-white mb-4">
          Some <span className="text-yellow-500">amazing</span>
          <br />
          brands I've worked with
        </div>
        <div className="max-w-3xl mx-auto grid md:grid-cols-2 px-4">
          <img
            className="mb-0"
            src="https://res.cloudinary.com/dl4c8irfl/image/upload/v1611174290/ryan-portfolio/redtag_2x_cmipyj.png"
          />
          <img
            className="mb-0"
            src="https://res.cloudinary.com/dl4c8irfl/image/upload/v1611174290/ryan-portfolio/chronic-ink_2x_dnnvqi.png"
          />
          <img
            className="mb-0"
            src="https://res.cloudinary.com/dl4c8irfl/image/upload/v1611174290/ryan-portfolio/snugglebugz_2x_ln98aw.png"
          />
          <img
            className="mb-0"
            src="https://res.cloudinary.com/dl4c8irfl/image/upload/v1611174290/ryan-portfolio/itravel2000_2x_jmcrlf.png"
          />
          <img
            className="mb-0"
            src="https://res.cloudinary.com/dl4c8irfl/image/upload/v1611174290/ryan-portfolio/coindera_2x_e0qufg.png"
          />
          <img
            className="mb-0"
            src="https://res.cloudinary.com/dl4c8irfl/image/upload/v1611174290/ryan-portfolio/urbanpost_2x_tbar96.png"
          />
          <img
            className="mb-0"
            src="https://res.cloudinary.com/dl4c8irfl/image/upload/v1611174290/ryan-portfolio/cookingchannel_2x_irmmm0.png"
          />
          <img
            className="mb-0"
            src="https://res.cloudinary.com/dl4c8irfl/image/upload/v1611175753/ryan-portfolio/case-studies_2x_tp2nrm.png"
          />
        </div>
      </div>

      <div className=" max-w-6xl mx-auto lg:flex flex-wrap items-center pt-24 px-4">
        <div className="text-3xl lg:text-4xl font-bold w-full mb-8">
          Some <span className="text-yellow-500">happy</span> clients :)
        </div>
        <div className="lg:w-3/5">
          <img
            className="rounded-lg"
            src="https://res.cloudinary.com/dl4c8irfl/image/upload/v1611174703/ryan-portfolio/urban-post-sample_2x_vbndzj.jpg"
          />
        </div>
        <div className="lg:w-2/5 p-6">
          <div className="text-xl font-bold">
            Ryan truly exceeded my expectations!
          </div>
          <div className="text-sm mt-4">
            Within 3 days, Ryan had a beautiful website design ready for me. 3
            weeks later and the job was done! Not only did Ryan craft a stylish
            up-to-date website that blows our competition out of the way, but he
            also maintained a smooth transition from the old website to the new
            one. I highly recommend him.
          </div>
          <div className="flex mt-4 ">
            <img
              className="w-14"
              src="https://res.cloudinary.com/dl4c8irfl/image/upload/v1611174897/ryan-portfolio/kingsley_2x_jvezgi.jpg"
            />
            <div className="ml-4">
              <div className="font-bold">Kinglsey Fialho</div>
              <div>Urban Post Production - Director of Sales</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="pt-40 lg:pt-60 pb-20 relative overflow-hidden"
        id="contactSection"
      >
        <div
          className="bg-primary-blue -z-10 w-screen absolute top-20 transform origin-top-right -skew-y-3"
          style={{ height: 2000 }}
        ></div>
        <div className="lg:flex mx-auto max-w-6xl px-4 lg:px-16">
          <form
            className="lg:w-1/2 px-4 text-white"
            name="contact"
            onSubmit={handleSubmit}
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <div className="text-3xl lg:text-4xl font-bold text-white mb-4">
              Shoot me a <span className="text-yellow-500"> message</span>
            </div>
            <div>
              <input type="hidden" name="form-name" value="contact" />
              <input
                className="bg-transparent border-b border-white p-2 w-full my-1 placeholder-white"
                type="text"
                id="name"
                onChange={handleChange}
                value={formState.name}
                name="name"
                placeholder="Name"
              />
            </div>
            <div>
              <input
                className="bg-transparent border-b border-white p-2 w-full my-1 placeholder-white"
                type="email"
                id="email"
                onChange={handleChange}
                value={formState.email}
                name="email"
                placeholder="Email"
              />
            </div>
            <div>
              <textarea
                className="bg-transparent border-b border-white p-2 w-full my-1 h-24 placeholder-white"
                name="message"
                id="message"
                onChange={handleChange}
                value={formState.message}
                placeholder="Message"
              ></textarea>
            </div>
            <div className="flex">
              <button
                className="bg-white font-bold text-primary-blue rounded-full py-2 px-5 ml-auto"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
          <div className="lg:w-1/2 text-white px-4 mt-16 lg:mt-0">
            <div className="text-3xl lg:text-3xl mb-4 font-bold">
              Currently available for hire
            </div>
            <div className="">
              Providing storyboards, sitemaps, screen flows, wireframes and
              mock-ups, responsive web design and application designs.
            </div>
            <div className="flex">
              <img
                className="w-10/12 ml-auto"
                src="https://res.cloudinary.com/dl4c8irfl/image/upload/v1609737313/ryan-portfolio/Design_Logos_2x_bpkhnf.png"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
